.home-page-wrapper {
    min-height: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;

    /* background-image: url('/public/main.jpg');
    background-size: cover;
    background-position: center;
    height: 100vh;
    width: 100%; */
}

.home-title {
    font-family: Neue Haas Grotesk;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 32px;
    margin: 10px 10px 0 10px;
}

.title-text {
    font-family: Neue Haas Grotesk;
    font-size: 44px;
    margin: 10px 0 10px 0;
}
.subtitle-text {
    font-family: Neue Haas Grotesk;
    font-size: 26px;
    margin: 0px 0 10px 0;
}
.paragraph-second {
    display: flex;
    flex-direction: column;
    max-width: 900px;
    font-size: 26px;
    margin: 20px;
}
.highlight-orange {
    color: #DD732B;
    transform: scale(3.23); 
    transform-origin: left center;
    font-weight: 900;
    font-size: 26px;
    margin-left: 10px;
    margin-top: -20px; 
    display: inline-block;  
    vertical-align: middle;
}
.highlight-blue {
    color: #336FA9;
    transform: scale(3.23); 
    transform-origin: left center;
    font-weight: 900;
    font-size: 26px;
    margin-left: 10px;
    margin-top: -20px; 
    display: inline-block;  
    vertical-align: middle;
}
.home-vid {
    height: 400px;
    width: 100%;
    max-width: 800px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
}
.home-img {
    height: 350px;
    width: 100%;
    max-width: 800px;
    /* margin: 20px 0 20px 0; */
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('/public/main.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.learn-button {
    margin: 10px 0px 20px 0px !important;
}