.Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #152126;
  padding: 0 20px;
  color: white;
}

.Nav {
  display: flex;
  gap: 20px;
}

.Nav a {
  color: white;
  text-decoration: none;
  padding: 10px 15px;
}
.logos {
  display: flex;
  flex-direction: row;
}
.Nav a:hover,
.Nav a:focus {
  background-color: #08aa54;
  border-radius: 5px;
}
.logo {
  width: 32px;
  height: 32px;
  background-image: url('../../../public/favicon.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  align-self: center;
}
.link {
  font-size: 16px;
}
.Brand-name {
  font-size: 36px;
  letter-spacing: 0.1ch;
  font-weight: bold;
  font-family: Neue Haas Grotesk;
  cursor: pointer;
  margin-left: 5px;
}

@media (max-width: 300px) {
  .link {
    font-size: 14px;
  }
  .Brand-name {
    font-size: 32px;
    letter-spacing: 0.1ch;
    font-weight: bold;
    font-family: Neue Haas Grotesk;
    cursor: pointer;
  }
  .logo {
    display: none;
  }
  .Nav {
    display: flex;
    gap: 8px;
  }
  .Nav a {
    color: white;
    text-decoration: none;
    padding: 5px;
  }
}

/* Transition styles */
.NavAnimation-enter {
  opacity: 0;
}
.NavAnimation-enter-active {
  opacity: 1;
  transition: opacity 350ms ease-in;
}
.NavAnimation-exit {
  opacity: 1;
}
.NavAnimation-exit-active {
  opacity: 0;
  transition: opacity 350ms ease-out;
}
