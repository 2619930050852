.footer {
  display: flex;
  justify-content: center; /* Wycentruj zawartość */
  align-items: center;
  background-color: #152126;
  padding: 0 20px;
  color: white;
  flex-direction: column; /* Ułóż elementy w kolumnie */
  min-height: 50px;
}

.Nav {
  display: flex;
  gap: 20px;
}

.Nav a {
  color: white;
  text-decoration: none;
  padding: 10px 15px;
}

.Nav a:hover,
.Nav a:focus {
  background-color: #08aa54;
  border-radius: 5px;
}

.link {
  font-size: 16px;
}

.footer-text {
  font-size: 14px;
  letter-spacing: 0.1ch;
  font-family: Neue Haas Grotesk;
  cursor: pointer;
}

@media (max-width: 800px) {
  .link {
    font-size: 14px;
  }
  .footer-text {
    font-size: 14px;
    letter-spacing: 0.1ch;
    font-weight: bold;
    font-family: Neue Haas Grotesk;
    cursor: pointer;
  }
  .Nav {
    display: flex;
    gap: 8px;
  }
  .Nav a {
    color: white;
    text-decoration: none;
    padding: 5px;
  }
}

/* Transition styles */
.NavAnimation-enter {
  opacity: 0;
}
.NavAnimation-enter-active {
  opacity: 1;
  transition: opacity 350ms ease-in;
}
.NavAnimation-exit {
  opacity: 1;
}
.NavAnimation-exit-active {
  opacity: 0;
  transition: opacity 350ms ease-out;
}
