.page {
  align-items: center; 
  display: flex;
  flex-direction: column;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 32px;
  padding: 24px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  background-color: #fff;
  max-width: 400px;
}

.title {
  font-size: 28px !important;
  font-family: 'Segoe UI' !important;
  margin-bottom: 16px;
}

.button {
  margin-top: 16px;
}

.action-buttons {
  display: flex;
  justify-content: space-between;
}